import { judge } from '@/games/yukon/judge';
import { CardMoveBaseController } from '@/core/card-move-base.controller';

export class MoveController extends CardMoveBaseController {
    constructor() {
        super({
            enableTableauAutoAdjust: true,
            meldCard: {
                validate: (cmd) => judge.canPutCardOnTopOf(cmd.card, cmd.destCard),
            },
            moveCardToFoundation: {
                validate: (cmd) => judge.canPutInSpecificFoundation(cmd.card, cmd.foundationIndex),
            },
            moveCardToEmptyTableauCmd: {
                validate: (cmd) =>
                    judge.canMoveToSpecificTableauEmptyFrame(cmd.card, cmd.tableauIndex),
            },
        });
    }
}
