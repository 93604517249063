import { CardOwner } from '@/core/models';
import { cardsService } from '@/state/cards/cards.service';
import { cardsQuery } from '@/state/cards/cards.query';
import { coreBus } from '@/core/core-bus';
import { CardDealerBaseController } from '@/core/card-dealer.base.controller';

export class DealerController extends CardDealerBaseController {
    constructor() {
        super(30, 52);
    }

    protected dealCard(i: number) {
        const card = cardsQuery.getTopByOwner(CardOwner.none);
        if (!card) {
            throw new Error('no card in stock');
        }

        // tableau 1
        if (i == 1) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 1,
                isFaceUp: true,
                order: 1,
                dragEnabled: true,
            });
        }

        // tableau 2
        if (i >= 2 && i <= 7) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 2,
                order: i - 1,
                isFaceUp: i > 2,
                dragEnabled: i > 2,
            });
        }

        // tableau 3
        if (i >= 8 && i <= 14) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 3,
                order: i - 7,
                isFaceUp: i > 9,
                dragEnabled: i > 9,
            });
        }

        // tableau 4
        if (i >= 15 && i <= 22) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 4,
                order: i - 14,
                isFaceUp: i > 17,
                dragEnabled: i > 17,
            });
        }

        // tableau 5
        if (i >= 23 && i <= 31) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 5,
                order: i - 22,
                isFaceUp: i > 26,
                dragEnabled: i > 26,
            });
        }

        // tableau 6
        if (i >= 32 && i <= 41) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 6,
                order: i - 31,
                isFaceUp: i > 36,
                dragEnabled: i > 36,
            });
        }

        // tableau 7
        if (i >= 42 && i <= 52) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 7,
                order: i - 41,
                isFaceUp: i > 47,
                dragEnabled: i > 47,
            });
        }

        coreBus.cardMoveCmd$.next({
            duration: 0,
            cardId: card.id,
        });
    }
}
