import { Card, CardOwner, Suit } from '@/core/models';
import { cardsQuery } from '@/state/cards/cards.query';
import { coreUtil } from '@/core/core-util';

export class Judge {
    canPutCardOnTopOf(card: Card, dest: Card) {
        if (dest.owner == CardOwner.tableau) {
            if (card.rank != dest.rank - 1) {
                return false;
            }
            if (card.ownerIndex == dest.ownerIndex) {
                return false;
            }
            if (
                (dest.suit == Suit.Heart || dest.suit == Suit.Diamond) &&
                (card.suit == Suit.Club || card.suit == Suit.Spade)
            ) {
                return true;
            }
            return (
                (dest.suit == Suit.Club || dest.suit == Suit.Spade) &&
                (card.suit == Suit.Heart || card.suit == Suit.Diamond)
            );
        }
        return false;
    }

    canPutInFoundation(card: Card) {
        if (card.owner == CardOwner.foundation) {
            return false;
        }

        // should be at the top
        const topTab = cardsQuery.getTopByOwnerAndIndex(card.owner, card.ownerIndex);
        if (!topTab || card.id != topTab.id) {
            return false;
        }

        const foundIndex = coreUtil.getFoundation4IndexForSuit(card.suit);
        const topFound = cardsQuery.getTopByOwnerAndIndex(CardOwner.foundation, foundIndex);
        if (!topFound) {
            return card.rank == 1;
        }
        return topFound.rank + 1 == card.rank;
    }

    canPutInSpecificFoundation(card: Card, foundIndex: number) {
        if (card.owner == CardOwner.foundation) {
            return false;
        }

        const suit = coreUtil.getFoundationSuitByIndex(foundIndex);
        if (card.suit != suit) {
            return false;
        }

        // should be at the top
        const topTab = cardsQuery.getTopByOwnerAndIndex(card.owner, card.ownerIndex);
        if (!topTab || card.id != topTab.id) {
            return false;
        }

        const topFound = cardsQuery.getTopByOwnerAndIndex(CardOwner.foundation, foundIndex);
        if (!topFound) {
            return card.rank == 1;
        }
        return topFound.rank + 1 == card.rank && topFound.suit == card.suit;
    }

    canMoveToTableauEmptyFrame(card: Card) {
        // only king can move into frame
        return card.rank == 13;
    }

    canMoveToSpecificTableauEmptyFrame(card: Card, ownerIndex: number) {
        if (!this.canMoveToTableauEmptyFrame(card)) {
            return false;
        }
        const top = cardsQuery.getTopByOwnerAndIndex(CardOwner.tableau, ownerIndex);
        return !top;
    }
}

export const judge = new Judge();
