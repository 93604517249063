import { Card, CardOwner, GameStatus, Suit } from '@/core/models';
import { BaseGameController } from '@/core/base-game.controller';
import { moveHistory } from '@/core/move-history';
import { gameQuery } from '@/state/game/game.query';
import { range } from 'lodash';
import { cardsService } from '@/state/cards/cards.service';
import { cardsQuery } from '@/state/cards/cards.query';
import { coreBus } from '@/core/core-bus';

export class GameController extends BaseGameController {
    constructor() {
        super();
        this.subscribeTo(gameQuery.gameStatus$, (status) => {
            if (status == GameStatus.dealCompleted) {
                if (process.env.NODE_ENV === 'development') {
                    setTimeout(() => this.setCustomBoard(), 2000);
                }
            }
        });
        this.initStage();
    }

    private initStage() {
        const suits = [Suit.Diamond, Suit.Spade, Suit.Club, Suit.Heart];
        const cards = suits.flatMap((s) => {
            return range(1, 14).map(
                (r) =>
                    ({
                        id: `${r}${s}`,
                        suit: s,
                        rank: r,
                        order: 0,
                        dragEnabled: false,
                        owner: CardOwner.none,
                    } as Card)
            );
        });
        cardsService.init(cards);
    }

    protected initGame(): void {
        cardsService.resetAllCards();
        moveHistory.reset();
    }

    protected isGameCompleted(): boolean {
        return cardsQuery.getAll().filter((c) => c.owner == CardOwner.foundation).length == 52;
    }

    private setCustomBoard() {
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        const dict = {
            f1: ['1H'],
            f2: ['1S'],
            f3: ['1D', '2D'],
            f4: [],
            t1: ['13H', '12H', '11H', '10H', '9H', '8H', '7H', '6H', '5H', '4H', '3H', '2H'],
            t2: ['13S', '12S', '11S', '10S', '9S', '8S', '7S', '6S', '5S', '4S', '3S', '2S'],
            t3: ['13C', '12C', '11C', '10C', '9C', '8C', '7C', '6C', '5C', '4C', '3C', '2C', '1C'],
            t4: ['12D', '11D', '10D', '9D', '8D', '7D', '6D', '5D', '4D', '3D', '13D'],
            t5: [],
            t6: [],
            t7: [],
        } as { [id: string]: string[] };

        const avails = cardsQuery.getAll().filter((card) => {
            const keys = Object.keys(dict);
            for (let k = 0; k < keys.length; k++) {
                const kd = dict[keys[k]];
                if (kd.indexOf(card.id) >= 0) {
                    return false;
                }
            }
            return true;
        });

        const f1 = (dict.f1 as string[]).map((id, i) => {
            const card = id != '' ? cardsQuery.getEntity(id) : avails.pop()!;
            return {
                ...card!,
                isFaceUp: id != '',
                dragEnabled: id != '',
                owner: CardOwner.foundation,
                ownerIndex: 1,
                order: i + 1,
            };
        });
        const f2 = (dict.f2 as string[]).map((id, i) => {
            const card = id != '' ? cardsQuery.getEntity(id) : avails.pop()!;
            return {
                ...card!,
                isFaceUp: id != '',
                dragEnabled: id != '',
                owner: CardOwner.foundation,
                ownerIndex: 2,
                order: i + 1,
            };
        });
        const f3 = (dict.f3 as string[]).map((id, i) => {
            const card = id != '' ? cardsQuery.getEntity(id) : avails.pop()!;
            return {
                ...card!,
                isFaceUp: id != '',
                dragEnabled: id != '',
                owner: CardOwner.foundation,
                ownerIndex: 3,
                order: i + 1,
            };
        });

        const f4 = (dict.f4 as string[]).map((id, i) => {
            const card = id != '' ? cardsQuery.getEntity(id) : avails.pop()!;
            return {
                ...card!,
                isFaceUp: id != '',
                dragEnabled: id != '',
                owner: CardOwner.foundation,
                ownerIndex: 4,
                order: i + 1,
            };
        });
        const t1 = (dict.t1 as string[]).map((id, i) => {
            const card = id != '' ? cardsQuery.getEntity(id) : avails.pop()!;
            return {
                ...card!,
                isFaceUp: id != '',
                dragEnabled: id != '',
                owner: CardOwner.tableau,
                ownerIndex: 1,
                order: i + 1,
            };
        });
        const t2 = (dict.t2 as string[]).map((id, i) => {
            const card = id != '' ? cardsQuery.getEntity(id) : avails.pop()!;
            return {
                ...card!,
                isFaceUp: id != '',
                dragEnabled: id != '',
                owner: CardOwner.tableau,
                ownerIndex: 2,
                order: i + 1,
            };
        });
        const t3 = (dict.t3 as string[]).map((id, i) => {
            const card = id != '' ? cardsQuery.getEntity(id) : avails.pop()!;
            return {
                ...card!,
                isFaceUp: id != '',
                dragEnabled: id != '',
                owner: CardOwner.tableau,
                ownerIndex: 3,
                order: i + 1,
            };
        });
        const t4 = (dict.t4 as string[]).map((id, i) => {
            const card = id != '' ? cardsQuery.getEntity(id) : avails.pop()!;
            return {
                ...card!,
                isFaceUp: id != '',
                dragEnabled: id != '',
                owner: CardOwner.tableau,
                ownerIndex: 4,
                order: i + 1,
            };
        });
        const t5 = (dict.t5 as string[]).map((id, i) => {
            const card = id != '' ? cardsQuery.getEntity(id) : avails.pop()!;
            return {
                ...card!,
                isFaceUp: id != '',
                dragEnabled: id != '',
                owner: CardOwner.tableau,
                ownerIndex: 5,
                order: i + 1,
            };
        });
        const t6 = (dict.t6 as string[]).map((id, i) => {
            const card = id != '' ? cardsQuery.getEntity(id) : avails.pop()!;
            return {
                ...card!,
                isFaceUp: id != '',
                dragEnabled: id != '',
                owner: CardOwner.tableau,
                ownerIndex: 6,
                order: i + 1,
            };
        });
        const t7 = (dict.t7 as string[]).map((id, i) => {
            const card = id != '' ? cardsQuery.getEntity(id) : avails.pop()!;
            return {
                ...card!,
                isFaceUp: id != '',
                dragEnabled: id != '',
                owner: CardOwner.tableau,
                ownerIndex: 7,
                order: i + 1,
            };
        });

        const all = f1
            .concat(f2)
            .concat(f3)
            .concat(f4)
            .concat(t1)
            .concat(t2)
            .concat(t3)
            .concat(t4)
            .concat(t5)
            .concat(t6)
            .concat(t7);
        cardsService.upsertMany(all);
        all.forEach((c) => {
            coreBus.cardMoveCmd$.next({
                cardId: c.id,
                duration: 0,
            });
        });
    }
}
