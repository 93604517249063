<template>
    <div>
        <div class="frame" :style="tableau1Style"></div>
        <div class="frame" :style="tableau2Style"></div>
        <div class="frame" :style="tableau3Style"></div>
        <div class="frame" :style="tableau4Style"></div>
        <div class="frame" :style="tableau5Style"></div>
        <div class="frame" :style="tableau6Style"></div>
        <div class="frame" :style="tableau7Style"></div>

        <div
            class="frame foundation heart noselect"
            :class="{ highlight: foundationHighlight === 1 }"
            :style="foundation1Style"
        >
            <div class="suit heart"></div>
        </div>
        <div
            class="frame foundation noselect"
            :class="{ highlight: foundationHighlight === 2 }"
            :style="foundation2Style"
        >
            <div class="suit spade"></div>
        </div>
        <div
            class="frame foundation noselect"
            :class="{ highlight: foundationHighlight === 3 }"
            :style="foundation3Style"
        >
            <div class="suit diamond"></div>
        </div>
        <div
            class="frame foundation noselect"
            :class="{ highlight: foundationHighlight === 4 }"
            :style="foundation4Style"
        >
            <div class="suit club"></div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { gameQuery } from '@/state/game/game.query';
import { subscribeTo } from '@/core/rxjs-helpers';
import { display } from '@/games/yukon/display';
import { recomputable } from '@/composable/recomputable';

export default defineComponent({
    setup() {
        const foundationHighlight = ref(0);
        const recompute = ref(0);

        subscribeTo(gameQuery.gameSize$, () => {
            setTimeout(() => {
                recompute.value += 1;
            });
        });

        subscribeTo(gameQuery.foundationIndexHighlight$, (index) => {
            if (index) {
                foundationHighlight.value = index;
            } else {
                foundationHighlight.value = 0;
            }
        });

        const getFrameStyle = (x: number, y: number) => {
            return {
                top: `${y}px`,
                left: `${x}px`,
                width: `${display.cardSize.width}px`,
                height: `${display.cardSize.height}px`,
                borderRadius: `${display.cardSize.width * 0.06}px`,
            };
        };

        const tableau1Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau1Position.x, display.tableau1Position.y);
        });

        const tableau2Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau2Position.x, display.tableau2Position.y);
        });

        const tableau3Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau3Position.x, display.tableau3Position.y);
        });

        const tableau4Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau4Position.x, display.tableau4Position.y);
        });

        const tableau5Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau5Position.x, display.tableau5Position.y);
        });

        const tableau6Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau6Position.x, display.tableau6Position.y);
        });

        const tableau7Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau7Position.x, display.tableau7Position.y);
        });

        const getFoundation = () => {
            return {
                lineHeight: `${display.cardSize.height}px`,
                fontSize: `${display.cardSize.height * 0.3}px`,
            };
        };

        const foundation1Style = recomputable(recompute, () => {
            const frame = getFrameStyle(
                display.foundation1Position.x,
                display.foundation1Position.y
            );
            return {
                ...frame,
                ...getFoundation(),
            };
        });

        const foundation2Style = recomputable(recompute, () => {
            const frame = getFrameStyle(
                display.foundation2Position.x,
                display.foundation2Position.y
            );
            return {
                ...frame,
                ...getFoundation(),
            };
        });

        const foundation3Style = recomputable(recompute, () => {
            const frame = getFrameStyle(
                display.foundation3Position.x,
                display.foundation3Position.y
            );
            return {
                ...frame,
                ...getFoundation(),
            };
        });

        const foundation4Style = recomputable(recompute, () => {
            const frame = getFrameStyle(
                display.foundation4Position.x,
                display.foundation4Position.y
            );
            return {
                ...frame,
                ...getFoundation(),
            };
        });

        return {
            foundationHighlight,
            tableau1Style,
            tableau2Style,
            tableau3Style,
            tableau4Style,
            tableau5Style,
            tableau6Style,
            tableau7Style,
            foundation1Style,
            foundation2Style,
            foundation3Style,
            foundation4Style,
        };
    },
});
</script>

<style scoped>
.frame {
    border: #777 1px solid;
    position: absolute;
    background-color: rgba(50, 50, 50, 0.5);
    pointer-events: none;
}
.frame.foundation {
    color: rgba(0, 0, 0, 1);
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
}
.frame.clickable {
    pointer-events: all;
    cursor: pointer;
}
.frame.highlight {
    -webkit-box-shadow: inset 0px 0px 10px 5px rgba(255, 255, 255, 1);
    -moz-box-shadow: inset 0px 0px 10px 5px rgba(255, 255, 255, 1);
    box-shadow: inset 0px 0px 10px 5px rgba(255, 255, 255, 1);
}
.frame.foundation .suit {
    background-position: center;
    -webkit-background-size: 40% 40%;
    -moz-background-size: 40%;
    -o-background-size: 40%;
    background-size: 40%;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0.6;
}
.frame.foundation .suit.heart {
    background-image: url('/img/heart.png');
}
.frame.foundation .suit.spade {
    background-image: url('/img/spade.png');
}
.frame.foundation .suit.diamond {
    background-image: url('/img/diamond.png');
}
.frame.foundation .suit.club {
    background-image: url('/img/club.png');
}
.frame.stock {
    text-align: center;
    vertical-align: center;
}
@media only screen and (max-width: 600px) {
    .frame.highlight {
        -webkit-box-shadow: inset 0px 0px 10px 5px rgba(255, 255, 255, 1);
        -moz-box-shadow: inset 0px 0px 10px 5px rgba(255, 255, 255, 1);
        box-shadow: inset 0px 0px 5px 2px rgba(255, 255, 255, 1);
    }
}
</style>
