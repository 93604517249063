import { Controller } from '@/core/controller';
import { judge } from '@/games/yukon/judge';
import { coreBus } from '@/core/core-bus';
import { gameService } from '@/state/game/game.service';
import { orderBy } from 'lodash';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';
import { cardsQuery } from '@/state/cards/cards.query';
import { Card, CardOwner, Hint } from '@/core/models';
import { cardSound } from '@/core/sound';

export class AutoMoveController extends Controller {
    private isAutoFinishInProgress = false;

    constructor() {
        super();
        this.subscribeTo(coreBus.cardClickEvent$, (ev) => {
            this.doBestMove(ev.card);
        });
        this.subscribeTo(coreBus.gameMoveCompletedEvent$, () => {
            if (this.checkIfAutoFinishPossible() && !this.isAutoFinishInProgress) {
                gameService.setCanAutoFinish(true);
            }
        });
        this.subscribeTo(coreBus.autoFinishCmd$, () => {
            if (this.checkIfAutoFinishPossible()) {
                this.doAutoFinish();
            }
        });
        this.subscribeTo(coreBus.generateHintCmd$, () => {
            this.generateHints();
        });
    }

    private doBestMove(card: Card) {
        if (card.owner != CardOwner.tableau) {
            return;
        }

        if (!card.isFaceUp) {
            return;
        }

        // try to move card to foundation
        for (let i = 1; i <= 4; i++) {
            if (judge.canPutInSpecificFoundation(card, i)) {
                console.log('---auto', i);
                coreBus.moveCardToFoundationCmd$.next({
                    card,
                    foundationIndex: i,
                });
                return;
            }
        }

        // try to meld card
        const tableauTops = cardsQuery.getTopTableauCards();
        for (let i = 0; i < tableauTops.length; i++) {
            const c = tableauTops[i];
            if (judge.canPutCardOnTopOf(card, c)) {
                coreBus.meldCardCmd$.next({
                    card,
                    destCard: c,
                });
                return;
            }
        }

        // try to move king card to empty tableau
        for (let i = 1; i <= 7; i++) {
            if (judge.canMoveToSpecificTableauEmptyFrame(card, i)) {
                coreBus.moveCardToEmptyTableauCmd$.next({
                    card,
                    tableauIndex: i,
                });
                return;
            }
        }

        // if we got here we could find any auto move
        // we want to shake the card
        coreBus.shakeCardCmd$.next({
            card,
        });

        // play sound
        cardSound.playError();
    }

    private checkIfAutoFinishPossible() {
        // check that all tableau piles are in order and faced up
        for (let i = 1; i <= 7; i++) {
            const cards = orderBy(
                cardsQuery.getAllByOwnerAndIndex(CardOwner.tableau, i),
                (c) => c.order
            );
            if (cards.length == 0) {
                continue;
            }
            if (cards.length == 1) {
                if (!cards[0].isFaceUp) {
                    return false;
                }
                continue;
            }
            for (let i = 0; i < cards.length - 1; i++) {
                if (!cards[i].isFaceUp) {
                    return false;
                }
                if (cards[i].rank <= cards[i + 1].rank) {
                    return false;
                }
            }
        }

        return true;
    }

    private doAutoFinish() {
        this.isAutoFinishInProgress = true;
        cardSound.startAutoFinish();

        const tabCards = cardsQuery.getAll().filter((c) => c.owner == CardOwner.tableau);
        const ordered = orderBy(tabCards, (c) => c.rank);

        const sub = interval(100)
            .pipe(take(ordered.length))
            .subscribe({
                next: (i) => {
                    const card = ordered[i];
                    for (let i = 1; i <= 4; i++) {
                        if (judge.canPutInSpecificFoundation(card, i)) {
                            coreBus.moveCardToFoundationCmd$.next({
                                card,
                                foundationIndex: i,
                            });
                        }
                    }
                },
                complete: () => {
                    this.isAutoFinishInProgress = false;
                    cardSound.endAutoFinish();
                },
            });

        this.subscription.add(sub);
    }

    private generateHints() {
        const tableauTops = cardsQuery.getTopTableauCards();
        const tabCards = cardsQuery.getAllByOwner(CardOwner.tableau);
        const foundTops = cardsQuery.getTopCardsByOwner(CardOwner.foundation);
        const hints = tabCards
            .concat(foundTops)
            .filter((c) => c.isFaceUp)
            .flatMap((c) => {
                return tableauTops
                    .filter((tc) => tc.ownerIndex != c.ownerIndex && judge.canPutCardOnTopOf(c, tc))
                    .map(
                        (tc) =>
                            ({
                                card1Id: c.id,
                                card2Id: tc.id,
                                priority: 0,
                            } as Hint)
                    );
            });

        gameService.setHints(hints);
    }
}
